import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import PersonalInformation from "./PersonalInformation/PersonalInformation";
import Quote from "./Quote/Quote";
import Lending from "./Lending/Lending";
import CarInformation from "./CarInformation/CarInformation";
import Income from "./Income/Income";
import BankInformation from "./BankInformation/BankInformation";
import Identification from "./Identification/Identification";
import Verification from "./Verification/Verification";
import SpeakToUs from "./SpeakToUs/SpeakToUs";
import { getForm } from "_helpers/form";
import SkyLogo from "_assets/img/logo.png";
import "./Application.scss";

function Application(props) {
  const navigate = useNavigate();
  let location = useLocation();
  const path = location?.pathname;

  const flow = {
    "/": {
      name: "verification",
      progress: 0,
      next: "/personal",
      previous: null,
    },
    "/personal": {
      name: "personal",
      progress: 33,
      next: "/lending",
      previous: null,
    },
    "/lending": {
      name: "lending",
      progress: 66,
      next: "/car",
      previous: "/personal",
    },
    "/car": {
      name: "car",
      progress: 90,
      next: "/quote",
      previous: "/personal",
    },
    "/quote": {
      name: "quote",
      progress: 90,
      next: null,
      previous: null,
    },
    // "/income": {
    //   name: "income",
    //   progress: 70,
    //   next: "/identification",
    //   previous: null,
    // },
    // "/identification": {
    //   name: "identification",
    //   progress: 83,
    //   next: "/bank",
    //   previous: "/income",
    // },
    // "/bank": {
    //   name: "bank",
    //   progress: 95,
    //   next: "/something",
    //   previous: "/identification",
    // },
  };

  const pageProps = {
    ...flow[path],
  };

  useEffect(() => {
    const currentForm = getForm();
    if (
      currentForm?.quote?.call_requested &&
      currentForm?.quote?.up_to_amount > 0
    ) {
      navigate("/quote");
    } else if (currentForm?.verification?.verified) {
      navigate("/personal");
    } else {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="app-box">
      <div className="app-headline">
        <img src={SkyLogo} alt="Sky Auto Finance" />
      </div>
      <Routes>
        <Route
          exact
          path="/"
          id="verification"
          element={<Verification {...props} {...pageProps} />}
        />
        <Route
          exact
          path="/personal"
          id="personal"
          element={<PersonalInformation {...props} {...pageProps} />}
        />
        <Route
          exact
          path="/lending"
          id="lending"
          element={<Lending {...props} {...pageProps} />}
        />
        <Route
          exact
          path="/car"
          id="car"
          element={<CarInformation {...props} {...pageProps} />}
        />
        <Route
          exact
          path="/quote"
          id="quote"
          element={<Quote {...props} {...pageProps} />}
        />
        <Route
          exact
          path="/complete"
          id="complete"
          element={<SpeakToUs {...props} {...pageProps} />}
        />
        {/*        <Route
          exact
          path="/income"
          id="income"
          element={<Income {...props} {...pageProps} />}
        />
        <Route
          exact
          path="/bank"
          id="bank"
          element={<BankInformation {...props} {...pageProps} />}
        />
        <Route
          exact
          path="/identification"
          id="identification"
          element={<Identification {...props} {...pageProps} />}
        />*/}
        {/*        <Route exact path="/test" element={<Test {...props} />} />
        <Route exact path="/sign-up" element={<Signup {...props} />} />*/}
      </Routes>
    </div>
  );
}

export default Application;
