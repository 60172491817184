import { Result } from "_styleguide";
import { IconMoodSmile } from "@tabler/icons-react";

function SpeakToUs(props) {
  return (
    <div className="step-start">
      <Result
        icon={<IconMoodSmile />}
        title="Great, someone will reach out to you shortly!"
      />
    </div>
  );
}

export default SpeakToUs;
