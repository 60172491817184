import React from "react";
import { Input as AntInput } from "_styleguide";
import { usePlacesWidget } from "react-google-autocomplete";
import { parseAddress } from "_assets/js/helpers";
import "./Autocomplete.scss";

function Autocomplete(props) {
  const { ref: antRef } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE,
    options: {
      types: ["address"],
    },
    onPlaceSelected: (place) => {
      const actual = parseAddress(place?.address_components);
      const newData = {
        address:
          (actual?.street_number?.long || "") +
          (actual?.route?.long ? " " + actual?.route?.long : ""),
        city:
          actual?.locality?.long ||
          actual?.administrative_area_level_3?.long ||
          actual?.sublocality_level_1?.long ||
          actual?.political?.long ||
          "",
        state: actual?.administrative_area_level_1?.short || "",
        zip: actual?.postal_code?.long || "",
      };
      props.updateAddress(newData);
    },
  });

  return (
    <AntInput
      placeholder=""
      value={props.value}
      onChange={(e) => props.onChange(e.target.value)}
      ref={(c) => {
        if (c) antRef.current = c.input;
      }}
      size="large"
    />
  );
}

export default Autocomplete;
