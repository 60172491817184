import React, { useEffect } from "react";
import { Actions, Result, Button, Title } from "_styleguide";
import { getForm } from "_helpers/form";
import { flatMoney } from "_assets/js/helpers";
import SuccessBanner from "_assets/images/success.svg";
import CanvasConfetti from "canvas-confetti";
import { IconStarFilled, IconPhoneCall, IconCar } from "@tabler/icons-react";
import { timeout } from "_assets/js/helpers";
import "./Quote.scss";

function Quote(props) {
  const personal = getForm("personal");
  const form = getForm("quote");

  const loanAmount = parseInt(form.up_to_amount || 0);
  const gotQuote = loanAmount > 3499;
  const notEnough = loanAmount < 3500 && loanAmount > 0;

  useEffect(() => {
    if (!gotQuote) return;
    const confetti = async () => {
      await timeout(50);
      CanvasConfetti({
        particleCount: 200,
        spread: 500,
        origin: { y: 0.4 },
        zIndex: 2147483647,
        startVelocity: 30,
      });
    };
    confetti();
  }, []);

  // let results = {
  //   status: "success",
  //   title: `Congrats you've been pre-approved for up to ${flatMoney(form.up_to_amount || 0)}`,
  //   subTitle: `Click next to start claiming your money`,
  // };
  // if (form.result === "pre-vin" || form.result === "pre-make") {
  //   if (form.quote_amount > personal.amount) {
  //     results = {
  //       status: "success",
  //       title: `Congrats you've been pre-approved for up to $${form.quote_amount || 0}`,
  //       subTitle: `Click next to start claiming your money`,
  //     };
  //   } else {
  //     results = {
  //       status: "success",
  //       title: `You have been approved for $${form.quote_amount} based on the value of your vehicle`,
  //       subTitle: `Based on the value of your car, you have been pre-approved for the amount above, click next to start claiming your money`,
  //     };
  //   }
  // }

  // if (form.result === "denied-blackbook") {
  //   results = {
  //     status: "warning",
  //     title: `Need more information`,
  //     subTitle: `We were unable to provide a quote based on the information you provided, to start another application click below`,
  //   };
  // }

  // if (form.result === "denied-prequal") {
  //   results = {
  //     status: "error",
  //     title: `Unable to provide a quote`,
  //     subTitle: `Based on the value of your car, we are unable to provide a quote at this time, click below to try again with a different car`,
  //   };
  // }

  return gotQuote ? (
    <div className="step-start step-quote">
      <div className="title-banner">
        <div className="success-banner">
          <img src={SuccessBanner} alt="" />
        </div>
        <Title>
          Congratulations, you have been pre-approved for up to{" "}
          {flatMoney(loanAmount)}!
        </Title>
      </div>
      <div className="app-form">
        <div className="next-steps">
          <h4>Next Steps & What to Expect</h4>
          <div className="next-step">
            <IconStarFilled />
            <div>
              <h5>Call With An Expert Advisor</h5>
              <p>
                Someone from our team will reach out to you to go over the
                information and help complete your application.
              </p>
            </div>
          </div>
          {/*          <div className="next-step">
            <IconStarFilled />
            <div>
              <h5>Program Onboarding</h5>
              <p>
                We'll guide you through the initial stages of your program,
                setting up any necessary arrangements and ensuring you're
                comfortable with the process.
              </p>
            </div>
          </div>*/}
        </div>
      </div>
    </div>
  ) : (
    <div className="step-start step-quote">
      <div className="title-banner">
        <div className="failure-banner">
          {notEnough ? <IconCar /> : <IconPhoneCall />}
        </div>
        <Title>
          {notEnough
            ? "Unable to provide a quote at this time"
            : "Need more information"}
        </Title>
      </div>
      <div className="app-form">
        <div className="next-steps">
          <h4>Next Steps</h4>
          {notEnough ? (
            <div className="next-step">
              <IconStarFilled />
              <div>
                <h5>Getting in touch</h5>
                <p>
                  Someone from our team will reach out to you to go over the
                  information to see if there is a way we can help.
                </p>
              </div>
            </div>
          ) : (
            <div className="next-step">
              <IconStarFilled />
              <div>
                <h5>Get the proper information</h5>
                <p>
                  Someone from our team will reach out to you to go over the
                  information and help complete your application.
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Quote;
