import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "_styleguide";
import { hasCategory, saveForm } from "_helpers/form";
import { saveApplication } from "_helpers/application";
import axios from "axios";
import "./Actions.scss";

function Actions(props) {
  const navigate = useNavigate();
  const [isSaving, setIsSaving] = useState(false);

  const getBlackbook = async (n) => {
    const carInfo = props.form?.car;
    const data = {
      car: carInfo,
      id: localStorage.getItem("sky_form_id") || null,
    };
    let blackbookValue = 0;
    try {
      const saveResults = await axios.post(
        `${process.env.REACT_APP_API_PATH}/blackbook`,
        data,
      );
      blackbookValue = saveResults?.data?.blackbook || 0;
    } catch (err) {
      console.log(err);
    }
    return blackbookValue || 0;
  };

  const handleNext = async (which) => {
    const category = props.name;
    if (hasCategory(category)) {
      setIsSaving(true);
      try {
        const formData = { ...props.form };
        if (category === "car") {
          const blackbookValue = await getBlackbook();
          formData.car.blackbook = blackbookValue;
          formData.quote.up_to_amount = blackbookValue * 0.45;
        }
        const saveData = await saveForm({ ...props.form }, category);
        if (props.application) {
          await saveApplication(props.application, saveData);
        }
      } catch (err) {
        console.log(err);
      }
      setIsSaving(false);
    }
    navigate(props[which]);
  };

  return (
    <div className="sky-actions">
      {props.previous && !props.customPrevious ? (
        <Button
          loading={isSaving}
          onClick={() => handleNext("previous")}
          type="secondary"
          size="large"
          block
        >
          Back
        </Button>
      ) : null}
      {props.customPrevious ? props.customPrevious : null}
      {props.next && !props.customNext ? (
        <Button
          disabled={props.nextDisabled}
          loading={isSaving}
          onClick={() => handleNext("next")}
          type="primary"
          size="large"
          block
        >
          Next
        </Button>
      ) : null}
      {props.customNext ? props.customNext : null}
    </div>
  );
}

export default Actions;
