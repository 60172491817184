import React, { useState } from "react";
import {
  Actions,
  Break,
  Header,
  Input,
  InputNumber,
  Label,
  Progress,
  Select,
  Subheader,
  SelectInput,
} from "_styleguide";
import { getMakes, getModels } from "car-info";
import { getForm } from "_helpers/form";
import axios from "axios";
import { options } from "./_data.js";

const makes = getMakes();
const years = Array.from(
  { length: 76 },
  (_, i) => new Date().getFullYear() - i,
);

function CarInformation(props) {
  const [form, setForm] = useState(getForm("car"));
  const [vinChecked, setVinChecked] = useState();

  // Update form by field and value
  const u = (val) => setForm({ ...form, ...val });

  const quote = getForm("quote");
  if (quote) {
    quote.call_requested = true;
  }

  const checkVin = async () => {
    if (form.vin?.length !== 17) return;
    if (vinChecked === form?.vin?.toUpperCase()) return;
    try {
      const saveResults = await axios.post(
        `${process.env.REACT_APP_API_PATH}/blackbook-data`,
        {
          vin: form.vin,
        },
      );
      const blackbookData = saveResults?.data?.blackbookData;
      if (!blackbookData) return;
      const prevForm = { ...form };
      if (blackbookData.vin) {
        prevForm.vin = blackbookData.vin;
        setVinChecked(blackbookData.vin);
      }
      if (blackbookData.make) prevForm.make = blackbookData.make;
      if (blackbookData.model) prevForm.model = blackbookData.model;
      if (blackbookData.year) prevForm.year = blackbookData.year;
      if (blackbookData.series) prevForm.series = blackbookData.series;
      if (blackbookData.style) prevForm.style = blackbookData.style;
      setForm(prevForm);
    } catch (err) {
      console.log(err);
    }
  };

  const isVinValid = form.vin?.length !== 17 ? false : true;
  const isDataValid = form.year && form.make && form.model;
  const isValid = (isVinValid || isDataValid) && form.mileage;

  return (
    <div className="step-car-information">
      <Progress
        className="sky-progress"
        percent={props.progress}
        showInfo={false}
      />
      <Header>Car Information</Header>
      <Subheader>
        Provide VIN & Mileage for faster and more accurate quote
      </Subheader>
      <div className="app-form">
        <div className="form-item">
          <Label htmlFor="vin">VIN #</Label>
          <Input
            id="vin"
            style={{ width: "100%" }}
            value={form.vin}
            onChange={(e) => u({ vin: e.target.value?.toUpperCase() })}
            size="large"
            onBlur={checkVin}
          />
        </div>
        <Break />
        <div className="form-item">
          <Label htmlFor="year">Year</Label>
          <Select
            value={form.year}
            onChange={(v) =>
              u({
                year: v,
                make: undefined,
                model: undefined,
                style: undefined,
                series: undefined,
              })
            }
            style={{ width: "100%" }}
            options={Object.keys(options).map((m) => ({ value: m, label: m }))}
            size="large"
          />
        </div>

        <div className="form-row">
          <div>
            <Label htmlFor="make">Make</Label>
            <SelectInput
              disabled={!form.year}
              options={Object.keys(options?.[form.year] || {}).map((m) => ({
                value: m,
                label: m,
              }))}
              value={form.make}
              onChange={(v) =>
                u({
                  make: v,
                  model: undefined,
                  style: undefined,
                  series: undefined,
                })
              }
              style={{ width: "100%" }}
              size="large"
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
            />
          </div>
          <div>
            <Label htmlFor="model">Model</Label>
            <SelectInput
              disabled={!form.make}
              options={Object.keys(options?.[form.year]?.[form.make] || {}).map(
                (m) => ({
                  value: m,
                  label: m,
                }),
              )}
              value={form.model}
              onChange={(v) =>
                u({ model: v, style: undefined, series: undefined })
              }
              style={{ width: "100%" }}
              size="large"
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
            />
          </div>
        </div>
        <div className="form-row">
          <div>
            <Label htmlFor="make">Style</Label>
            <SelectInput
              disabled={!form.model}
              options={Object.keys(
                options?.[form.year]?.[form.make]?.[form.model] || {},
              ).map((m) => ({
                value: m,
                label: m,
              }))}
              value={form.style}
              onChange={(v) => u({ style: v, series: undefined })}
              style={{ width: "100%" }}
              size="large"
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
            />
          </div>
          <div>
            <Label htmlFor="model">Series</Label>
            <SelectInput
              disabled={!form.style}
              options={Object.keys(
                options?.[form.year]?.[form.make]?.[form.model]?.[form.style] ||
                  {},
              ).map((m) => ({
                value: m,
                label: m,
              }))}
              value={form.series}
              onChange={(v) => u({ series: v })}
              style={{ width: "100%" }}
              size="large"
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
            />
          </div>
        </div>
        <div className="form-item">
          <Label htmlFor="mileage">Est. Current Mileage</Label>
          <InputNumber
            id="mileage"
            min={1}
            max={10000000}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            style={{ width: "100%" }}
            value={form.mileage}
            onChange={(v) => u({ mileage: v })}
            size="large"
          />
        </div>
      </div>
      <Actions
        {...props}
        nextDisabled={!isValid}
        form={{ car: form, quote: quote }}
        application={["zoho", "vergent"]}
      />
    </div>
  );
}

export default CarInformation;
