import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Actions,
  Header,
  Input,
  Label,
  Progress,
  Subheader,
  Select,
  Button as StyledButton,
} from "_styleguide";
import { Button } from "antd";
import { getForm } from "_helpers/form";
import { formatExpiration } from "_assets/js/helpers";

function BankInformation(props) {
  const navigate = useNavigate();

  const [form, setForm] = useState(getForm("bank"));
  const [debit, setDebit] = useState(getForm("debit"));
  const [personal, setPersonal] = useState(getForm("personal"));

  const handleToggle = (type) => {
    const n = { ...personal };
    n.payment_type = type;
    setPersonal(n);
  };

  // Update form by field and value
  const u = (f, v) => {
    const n = { ...form };
    n[f] = v;
    setForm(n);
  };

  // Update form by field and value
  const ud = (f, v) => {
    const n = { ...debit };
    n[f] = v;
    setDebit(n);
  };

  const selected = personal?.payment_type === "debit" ? "debit" : "bank";

  return (
    <div className="step-loan">
      <Progress
        className="sky-progress"
        percent={props.progress}
        showInfo={false}
      />
      <Header>Bank Information</Header>
      <Subheader>Where can we send the money?</Subheader>
      <div className="app-form">
        <div className="payment-toggle">
          <Button.Group size="large">
            <Button
              type={selected === "bank" ? "primary" : "default"}
              onClick={() => handleToggle("bank")}
              style={{ flex: 1 }}
            >
              Bank Account
            </Button>
            <Button
              type={selected === "debit" ? "primary" : "default"}
              onClick={() => handleToggle("debit")}
              style={{ flex: 1 }}
            >
              Debit Card
            </Button>
          </Button.Group>
        </div>
        {selected === "bank" ? (
          <>
            <div className="form-row">
              <div>
                <Label htmlFor="bank_name">Bank name</Label>
                <Input
                  id="bank_name"
                  value={form.bank_name}
                  onChange={(e) => u("bank_name", e.target.value)}
                  size="large"
                />
              </div>
              <div style={{ maxWidth: "160px" }}>
                <Label htmlFor="account_type">Account type</Label>
                <Select
                  value={form.account_type}
                  onChange={(v) => u("account_type", v)}
                  style={{ width: "100%" }}
                  options={[
                    { value: "checking", label: "Checking" },
                    { value: "savings", label: "Savings" },
                  ]}
                  size="large"
                />
              </div>
            </div>
            <div className="form-item">
              <Label htmlFor="routing_number">Routing number</Label>
              <Input
                id="routing_number"
                value={form.routing_number}
                onChange={(e) => u("routing_number", e.target.value)}
                size="large"
              />
            </div>
            <div className="form-item">
              <Label htmlFor="account_number">Account number</Label>
              <Input
                id="account_number"
                value={form.account_number}
                onChange={(e) => u("account_number", e.target.value)}
                size="large"
              />
            </div>
          </>
        ) : null}
        {selected === "debit" ? (
          <>
            <div className="form-item">
              <Label htmlFor="bank_name">Name on Card</Label>
              <Input
                id="card_name"
                value={debit.card_name}
                onChange={(e) => ud("card_name", e.target.value)}
                size="large"
              />
            </div>
            <div className="form-item">
              <Label htmlFor="card_number">Card Number</Label>
              <Input
                id="card_number"
                value={debit.card_number}
                onChange={(e) => ud("card_number", e.target.value)}
                size="large"
              />
            </div>
            <div className="form-row">
              <div>
                <Label htmlFor="card_expiration">Expiration</Label>
                <Input
                  id="card_expiration"
                  value={debit.card_expiration}
                  onChange={(e) =>
                    ud("card_expiration", formatExpiration(e.target.value))
                  }
                  size="large"
                />
              </div>
              <div>
                <Label htmlFor="cvv">CVV</Label>
                <Input
                  id="cvv"
                  value={debit.cvv || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Allow only numeric input with a maximum length of 4 digits
                    if (/^\d{0,4}$/.test(value)) {
                      ud("cvv", value);
                    }
                  }}
                  size="large"
                  maxLength={4}
                />
              </div>
              <div style={{ maxWidth: "160px" }}>
                <Label htmlFor="billing_zip">Billing Zip</Label>
                <Input
                  id="billing_zip"
                  value={debit.billing_zip || ""}
                  onChange={(e) => {
                    const value = e.target.value;
                    // Only allow numeric input with a maximum length of 5 digits
                    if (/^\d{0,5}$/.test(value)) {
                      ud("billing_zip", value);
                    }
                  }}
                  size="large"
                  maxLength={5}
                />
              </div>
            </div>
          </>
        ) : null}
      </div>
      <Actions
        {...props}
        customNext={
          <StyledButton
            onClick={() => navigate("/complete")}
            type="primary"
            size="large"
            block
          >
            Submit Form
          </StyledButton>
        }
        form={{ bank: form, debit: debit, personal: personal }}
        application={["zoho", "vergent"]}
      />
    </div>
  );
}

export default BankInformation;
