import { Actions, Result, Title } from "_styleguide";
import { getForm } from "_helpers/form";
import { allowedStates, STATES } from "_helpers/states";
import SuccessBanner from "_assets/images/success.svg";
import { IconStarFilled, IconAlertOctagon } from "@tabler/icons-react";
import "./Lending.scss";

function Lending(props) {
  const location = getForm("location");

  const isAllowed = allowedStates.includes(location?.state);
  const currentState = STATES.find((s) => s.value === location?.state)?.label;

  // subTitle: `We are not currently lending in ${currentState}. We'll be in touch if that changes.`,

  // let results = {
  //   status: "success",
  //   title: `Great News!`,
  //   subTitle: `We are lending in your state. Let's proceed to your vehicle details.`,
  // };
  // if (!isAllowed) {
  //   results = {
  //     status: "warning",
  //     title: `Sorry, We Cannot Lend `,
  //     subTitle: `We are not currently lending in ${currentState}. We'll be in touch if that changes.`,
  //   };
  // }

  const actionProps = { ...props, next: isAllowed ? props.next : null };

  return isAllowed ? (
    <div className="step-start step-success">
      <div className="title-banner">
        <div className="success-banner">
          <img src={SuccessBanner} alt="" />
        </div>
        <Title>Great News!</Title>
      </div>
      <div className="app-form">
        <p className="success-info">
          We are currently lending in your state. Let's continue by providing us
          your vehicle details.
        </p>
        <Actions {...actionProps} />
      </div>
    </div>
  ) : (
    <div className="step-start step-quote">
      <div className="title-banner">
        <div className="failure-banner">🤷‍♂️</div>
        <Title>We are not currently lending in {currentState}</Title>
      </div>
      <div className="app-form">
        <p className="success-info">We'll be in touch if anything changes.</p>
        <Actions {...actionProps} />
      </div>
    </div>
  );
}

export default Lending;
